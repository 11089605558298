.menu-section {
  /* background-image: url('./main-page.jpg'); */
  background-color: #746861;
  background-image: url('./imagens/imagem_menu.svg');
  background-size: contain;
  background-repeat: no-repeat;
  height: 3000px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in;
  flex-direction: column;
}

.menu-images-carroussel {
  background-image: url('./First-menu-image.jpeg');
  background-size: cover;
  /* Ensures the image covers the entire section */
  background-position: bottom;
  /* Centers the image */
  background-repeat: no-repeat;
  /* Prevents repeating if the image is smaller than the container */
  height: 100vh;
  /* Makes the section take up the full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;
  /* Left half for the image carousel */
}

.menu-description {
  width: 50%;
  /* Right half for the title and description */
  padding: 20px;
  /* Add some padding for spacing */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 48px;
  align-items: center;
  background-color: #fff;
  /* Background color for the right section */
}

.menu-description-title {
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  /* Adjust the font size */
  align-items: center;
  margin-bottom: 10px;
  /* Space between title and description */
  width: 100%;
  font-family: 'Poppins', Montserrat, sans-serif;
  color: #746861;
}

.menu-description-text {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  /* Adjust the text size */
  color: #746861;
  /* Text color */
  font-family: 'Poppins', Montserrat, sans-serif;
  font-weight: lighter;
  width: 50%;
  text-align: center;
}


.menu-buttons {
  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-evenly;
}

.button-food-menu {
  padding: 12px 20px;
  font-size: 14px;
  background-color: inherit;
  color: #746861;
  border: none;
  cursor: pointer;
  position: relative;
  /* Necessário para as pseudo-classes before e after */
  transition: background-color 0.3s, color 0.3s;
  /* Transições para a cor de fundo e texto */
}

.button-food-menu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #746861;
  /* Cor da linha superior */
  transform: scaleY(0);
  transition: transform 0.5s ease-in-out;
  /* Transição para a linha superior */
  transform-origin: left;
  /* Ponto de origem da transição */
}

.button-food-menu::after {
  content: '';
  position: absolute;
  bottom: 0;
  /* Posição inicial da linha inferior */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #746861;
  /* Cor da linha inferior */
  transition: bottom 0.3s ease-in-out;
  /* Transição para a linha inferior */
}

.button-food-menu:hover {
  color: #746861;
}

.button-food-menu:hover::before {
  transform: scaleY(1);
  /* Faz a linha superior aparecer */
}

.button-food-menu:hover::after {
  bottom: 2px;
  /* Move a linha inferior para cima */
  cursor: pointer;
}


.button-drinks-menu {
  padding: 12px 20px;
  font-size: 14px;
  background-color: inherit;
  color: #746861;
  border: none;
  cursor: pointer;
  position: relative;
  /* Necessário para as pseudo-classes before e after */
  transition: background-color 0.3s, color 0.3s;
  /* Transições para a cor de fundo e texto */
}

.button-drinks-menu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #746861;
  /* Cor da linha superior */
  transform: scaleY(0);
  transition: transform 0.5s ease-in-out;
  /* Transição para a linha superior */
  transform-origin: left;
  /* Ponto de origem da transição */
}

.button-drinks-menu::after {
  content: '';
  position: absolute;
  bottom: 0;
  /* Posição inicial da linha inferior */
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #746861;
  /* Cor da linha inferior */
  transition: bottom 0.3s ease-in-out;
  /* Transição para a linha inferior */
}

.button-drinks-menu:hover {
  color: #746861;
}

.button-drinks-menu:hover::before {
  transform: scaleY(1);
  /* Faz a linha superior aparecer */
}

.button-drinks-menu:hover::after {
  bottom: 2px;
  /* Move a linha inferior para cima */
  cursor: pointer;
}




@media (max-width: 1440px) {

  .menu-to-catering {
    margin-top: -200%;
  }

}


@media (max-width: 1080px) {

  .menu-to-catering {
    margin-top: -100%;
  }

}

@media (max-width: 780px) {

  .menu-to-catering {
    margin-top: -50%;
  }

}


@media (max-width: 476px) {


  .menu-section {
    height: 2% !important;
  }

}