body {
  background: transparent;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease-in; /* Smooth transition for the opacity */
}

body.fade-in {
  opacity: 1; /* Fully visible */
}

.App {
  color: #F9f7DC;
  background: #000;
  max-width: 100%;
}

@media (max-width: 765px) {

}

@media (max-width: 476px) {

}