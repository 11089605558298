body,
html,
#root {
    margin: 0;
    height: 100%;
    width: 100%;
}

@font-face {
    font-family: 'Catalogue';
    src: url('./Fonts/Catalogue.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

.navbar-section {
    font-family: 'Poppins', Montserrat, sans-serif;
    max-width: 73px;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    transition: all 0.3s ease;
    display: flex;
    justify-content: center;
    border-right: 1px solid rgb(31, 14, 10);
}


.background-container {
    display: flex;
    max-width: 100%;
}

.navbar-container {
    display: flex;
    width: 100%;
}

.navbar-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    transition: all 0.5s ease-in-out;
    justify-content: space-between;
}

.navbar-header.open {
    background-color: #1f0e0ac4;;
}


.menu-button-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    height: 100%;
}

.menu-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;
    margin-left: 24px;
    height: 24px;
    margin-top: 48px;
    background: none;
    margin-right: 24px;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;
    /* Ensure the button is above other content */
    transition: all 0.3s ease;
}

.shrink .menu-button {
    margin-bottom: -200px;
}

.menu-button-text {
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    font-size: 16px;
    text-align: center;
    display: inline-block;
    margin: 10px 0;
    font-family: 'Poppins', Montserrat, sans-serif;
    font-weight: lighter;
}

.menu-options {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    opacity: 0;
    transform: translateY(-50px); /* Start off 50px above */
    transition: all 0.5s ease-in-out;
    height: 100%;
    pointer-events: none;
  }
  
  .menu-options.open {
    opacity: 1;
    transform: translateY(0); /* Return to original position */
    pointer-events: auto    ;
  }



.navbar-logo-text {
    position: sticky;
    font-family: 'Poppins', Montserrat, sans-serif;
    font-size: 72px;
    transition: all 0.5s ease-in-out;
    height: 100%;
}

.navbar-logo-text.open {
    opacity: 1;
    color: #F9f7DC;
}

.menu-nav-option{
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

.about-nav-option{
    text-decoration: none;
    color: inherit;
}

.home-nav-option{
    text-decoration: none;
    color: inherit;
    font-family: 'Catalogue', sans-serif;
}


.navbar-social-icons-container {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}


.navbar-social-icons {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    gap: 32px;
}



.navbar-social-icons li {
    gap: 96px;
}

.navbar-social-icons li a {
    text-decoration: none;
    color: #F9f7DC;
    font-size: 16px;
}


@media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {


        .menu-button {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 30px;
            height: 24px;
            margin-top: 72px;
            background: none;
            margin-right: 50px;
            border: none;
            cursor: pointer;
            padding: 0;
            z-index: 10;
            /* Ensure the button is above other content */
            transition: all 0.3s ease;
        }

        .shrink .menu-button {
            margin-bottom: -200px;
        }
    }
}


.menu-button .bar2 {
    display: none;
    width: 100%;
    height: 4px;
    background-color: #746861;
    transition: all 0.3s ease-in-out;
}

.menu-button .bar1,
.menu-button .bar3 {
    width: 100%;
    height: 4px;
    background-color: #746861;
    transition: all 0.7s ease-in-out;
}

.open .bar1 {
    background-color: #746861;
    transform: translateY(10px) rotate(-45deg);
    /* Translação e rotação para formar metade do "X" */
}

/* Barra 2 (escondida) */
.open .bar2 {
    display: none;
}

/* Barra 3 */
.open .bar3 {
    background-color: #F9f7DC;
    transform: translateY(0px) rotate(45deg);
    /* Translação e rotação para formar a outra metade do "X" */
}




@media (max-width: 765px) {
   
}

@media (max-width: 476px) {
    .navbar-section {
        font-family: 'Poppins', Montserrat, sans-serif;
        max-width: 73px;
        overflow: hidden;
        height: 100vh;
        position: fixed;
        z-index: 1000;
        /* Ensure it's above other content */
        transition: all 0.3s ease;
        display: flex;
        justify-content: center;
        border-right: 1px solid rgb(31, 14, 10);
    }
}

@media (max-width:300px) {
    
}