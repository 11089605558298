.footer {
    background-color: #F9f7DC;
    font-family: Popins, sans-serif;
    width: 100%;
    height: 196px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.footer-bottom {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    align-items: center;
    padding: 24px;
    margin-left: 5%;
    width: 100%;
}

.footer-first{
    font-family: Popins, sans-serif;
    font-size: 20px;
    color: #746861;
}


.address{
    text-decoration: none;
    color: #746861;
    font-family: Poppins, sans-serif;
    font-weight: lighter;
}

.footer-second{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer-email{
    display: flex;
    flex-direction: column;
    gap: 2px;
    color:#F9f7DC
}


.footer-telephone{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.telephone-disclaimer{
    color:#746861;
}

.email-disclaimer{
    color:#746861;
}


.footer-email a {
    text-decoration: none;
    color: #746861;
    font-family: Poppins, sans-serif;
    font-weight: lighter;
}

.footer-telephone a {
    text-decoration: none;
    color: #746861;
    font-family: Poppins, sans-serif;
    font-weight: lighter;
}

.whatsapp-button button{
    background: none;
    border: none;
    font-size: 32px;
    cursor: pointer;
    color: #746861;
}


.footer-copyright {
    font-size: 12px;
    text-align: right;
    font-family: Popins, sans-serif;
    height: 100%;
    display: flex;
    align-items: flex-end;  
    color: #746861;
}


@media (max-width: 1080px){
    .footer-bottom {
        
        margin-left: 7%;
    }
}

@media (max-width: 476px) {
    
    .footer {
        font-family: Popins, sans-serif;
        height: 440px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    
    
    .footer-bottom {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        align-items: center;
        padding: 24px;
        margin-left: 5%;
        width: 80%;
    }
    
    .footer-first{
        font-family: Popins, sans-serif;
        font-size: 20px;
    }
    
    
    .address{
        display: flex;
        text-decoration: none;
        font-family: Poppins, sans-serif;
        font-weight: lighter;
        align-items: center;
        text-align: center;
    }
    
    .footer-second{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .footer-email{
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
        text-align: center;
    }
    
    .telephone-disclaimer{
        color: #746861;
    }

    .email-disclaimer{
        color: #746861;
    }

    .footer-telephone{
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
        text-align: center;
        width: 50%;
    }
    
    
    
    .footer-email a {
        text-decoration: none;
        font-family: Poppins, sans-serif;
        font-weight: lighter;
    }
    
    .footer-telephone a {
        text-decoration: none;
        font-family: Poppins, sans-serif;
        font-weight: lighter;
    }
    
    .whatsapp-button button{
        background: none;
        border: none;
        font-size: 32px;
        cursor: pointer;
    }
    
    
    .footer-copyright {
        font-size: 12px;
        text-align: right;
        font-family: Popins, sans-serif;
        height: 100%;
        display: flex;
        align-items: flex-end;  
    }
    
    
  }