
@font-face {
  font-family: 'Catalogue';
  src: url('../..//Fonts/Catalogue.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.container{
  max-width: 100%;
}

.background-image {
  /* background-image: url('./main-page.jpg'); */
  background-image: url('../../imagens/IMG_5884.jpg');
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: bottom; /* Centers the image */
  background-repeat: no-repeat; /* Prevents repeating if the image is smaller than the container */
  height: 100vh; /* Makes the section take up the full viewport height */
  max-width: 100%; /* Ensures the section takes the full viewport width */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in; /* Smooth transition for the opacity */
  flex-direction: column;
}

.phrases-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligns to the left horizontally */
  align-items: flex-start; /* Centers vertically */
  max-width: 100%;
  margin-left: -10%;
  margin-top: -10%;
}

.mainLogo {
  position: relative;
  font-family: 'Catalogue', sans-serif ;
  font-size: 8em;  
}

.mainLogoImage {
  width: 100%; /* Fill the width of the div */
  height: 100%; /* Fill the height of the div */
  object-fit: cover; /* Ensure the image covers the entire div without being distorted */
}

.phrases h2 {
  font-size: 84px; /* Adjust font size if necessary */
  color: #F9f7DC; /* Add a color if it's not visible enough */
  font-family: 'Poppins', Montserrat, sans-serif;
  font-weight: lighter;
  letter-spacing: 4px;
  margin-top: 32px;
}

.scrolldown-note{
  display: block;
  position: absolute;
  bottom: 24px;
  right: 0;
}


.navbar-section{
  background: none;
}


.about-section{
  background-color: #F9f7DC;
}

.footer{
  margin-top: 0vh;
  background: none;
  max-width: 100%;
}


.scrolldown-note-button{
  font-family: Garamond;
  font-size: 16px;
  background: none;
  border: none;
  color: #F9f7DC;
  cursor: pointer;
}



@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance: none) {
    .navigation-section {
      height: 100%;
    }
  }
}

.gallery-section{
  opacity: 0;
  transition: opacity 1s ease-in-out; /* Add transition for fade-in effect */
}

.gallery-section.fade-in{
  opacity: 1;
}


@media (max-width: 1080px) {
  .background-image {
    /* background-image: url('./main-page.jpg'); */
    background-image: url('../../imagens/imagem_5884_cellphone.jpeg');
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: bottom; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating if the image is smaller than the container */
    height: 100vh; /* Makes the section take up the full viewport height */
    max-width: 100%; /* Ensures the section takes the full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in; /* Smooth transition for the opacity */
    flex-direction: column;
  }

}

@media (max-width: 476px) {
  .background-image {
    /* background-image: url('./main-page.jpg'); */
    background-image: url('../../imagens/imagem_5884_cellphone.jpeg');
    background-size: cover; /* Ensures the image covers the entire section */
    background-position: bottom; /* Centers the image */
    background-repeat: no-repeat; /* Prevents repeating if the image is smaller than the container */
    height: 100vh; /* Makes the section take up the full viewport height */
    max-width: 100%; /* Ensures the section takes the full viewport width */
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s ease-in; /* Smooth transition for the opacity */
    flex-direction: column;
  }


  

}